import React from "react";
import ContactUsHeaderImage from "../Images/sections_image/Contact Us.avif";
const ContactUs = () => {
  return (
    <div className="suppliers-component">
      <div>
        <img
          className="img-fluid"
          src={ContactUsHeaderImage}
          alt="Products"
          style={{ width: "100%", height: "55vh", objectFit: "cover" }}
        />
      </div>
      <section id="contact" className="services ">
        <div className="container mb-4">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="section-header">
                <span>Contact Us</span>
                <h2>Contact Us</h2>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
              {/* <h3>About Us</h3> */}
              <p className="text-justify">
                Whether you have inquiries, collaboration proposals, or need
                assistance, our dedicated team is ready to respond promptly.
                <br />
                <br />
                <b>
                  Reach out today, and let's start a conversation that propels
                  your business forward.
                </b>
              </p>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
              {/* <h3>You can reach us at</h3> */}
              <p className="text-justify">
                <b>Address: </b>I-91 Site- C, Surajpur Industrial Area, UPSIDC,
                <br />
                Greater Noida,
                <br />
                <b>PIN: </b>201306 <br />
                <b>Phone: </b>+919818080803, +917017558992
                <br />
                <b>Email: </b>info@amrutsya.com
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* { modalShow && 
                <ModalComponent
                show={modalShow}
                data={selectedProduct}
                onHide={() => setModalShow(false)}
              />
                
                } */}
    </div>
  );
};

export default ContactUs;
